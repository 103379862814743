import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../css/Contact.css";

function Contact() {
  const [selectedOption, setSelectedOption] = useState("email");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedOption === "email") {
      sendEmail();
    } else if (selectedOption === "whatsapp") {
      sendWhatsApp();
    }
  };

  const sendEmail = () => {
    const serviceID = "service_5fkahd1"; // Coloca aquí tu Service ID
    const templateID = "template_a6c56ra"; // Coloca aquí tu Template ID
    const userID = "qsEEss7Jj_Vcekc-9"; // Coloca aquí tu User ID de EmailJS

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        alert("Mensaje enviado por correo electrónico con éxito!");
      })
      .catch((error) => {
        alert(
          "Ocurrió un error al enviar el correo electrónico: " + error.text
        );
      });
  };

  const sendWhatsApp = () => {
    const phoneNumber = "541134432534";
    const message = `Nombre: ${formData.name}, Mensaje: ${formData.message}`;

    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };

  return (
    <section id="contact" className="contact">
      <h2>Contacto</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Nombre"
          required
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Correo electrónico"
          required
          onChange={handleChange}
        />
        <textarea
          name="message"
          placeholder="Escribe tu mensaje aquí"
          required
          onChange={handleChange}
        ></textarea>

        <div>
          <label>
            <input
              type="radio"
              value="email"
              checked={selectedOption === "email"}
              onChange={handleOptionChange}
            />
            Enviar por Email
          </label>
          <label>
            <input
              type="radio"
              value="whatsapp"
              checked={selectedOption === "whatsapp"}
              onChange={handleOptionChange}
            />
            Enviar por WhatsApp
          </label>
        </div>

        <button type="submit">Enviar</button>
      </form>
    </section>
  );
}

export default Contact;
