import React from "react";
import "../css/Services.css";

function Services() {
  return (
    <section id="services" className="services">
      <h2>Servicios de Counseling</h2>
      <div className="services-grid">
        <div className="service-item">
          <i className="fas fa-user"></i>
          <h3>Consultas de Counseling</h3>
          <p>
            Encuentros de acompañamiento emocional para personas que buscan
            orientación y apoyo en momentos de dificultad.
          </p>
        </div>
        <div className="service-item">
          <i className="fas fa-user"></i>
          <h3>Counseling en Duelo</h3>
          <p>Te brindo apoyo emocional para atravesar el proceso de duelo.</p>
        </div>
        <div className="service-item">
          <i className="fas fa-hand-holding-heart"></i>
          <h3>
            Orientación para al Cuidador Familiar de Personas con Alzheimer
          </h3>
          <p>
            Te ofrezco acompañamiento y herramientas para familiares que cuidan
            a personas con Alzheimer.
          </p>
        </div>
        <div className="service-item">
          <i className="fas fa-video"></i>
          <h3>Consultas Online</h3>
          <p>Acompañamiento emocional desde la comodidad de tu hogar.</p>
        </div>
      </div>
    </section>
  );
}

export default Services;
