import React from "react";
import "../css/Header.css";

function Header() {
  return (
    <header className="header">
      <div className="logo">Counselor Cristina Leonhardt</div>
      <nav className="navbar">
        <ul>
          <li>
            <a href="#services">Servicios</a>
          </li>
          <li>
            <a href="#about">Sobre mí</a>
          </li>
          <li>
            <a href="#testimonials">Testimonios</a>
          </li>
          <li>
            <a href="#contact">Contacto</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
