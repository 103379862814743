import React from "react";
import "../css/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 Counseling. Todos los derechos reservados.</p>
      <div className="social-media">
        <a target="_blank" href="https://www.facebook.com/profile.php?id=100079746669563">
          Facebook
        </a>
        <a href="#">Instagram</a>
      </div>
    </footer>
  );
}

export default Footer;
