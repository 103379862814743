import React from "react";
import "../css/About.css";

function About() {
  return (
    <section id="about" className="about">
      <div className="about-content">
        <img
          src="../assets/counselor.jpg"
          alt="Counselor"
          className="about-img"
        />
        <div className="about-text">
          <h2>Sobre mí</h2>
          <p>
            Como counselor profesional, mi enfoque se basa en ofrecer un espacio
            seguro y confiable para que las personas puedan explorar sus
            emociones y trabajar en su crecimiento personal. Mi objetivo es
            acompañarte en este camino hacia tu bienestar emocional.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
