import React from "react";
import "../css/Hero.css";

function Hero() {
  return (
    <section className="hero">
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1>Encuentra el equilibrio que mereces</h1>
        <p>
          Descubre un espacio de confianza donde puedas crecer, conectar y
          alcanzar tu bienestar emocional, con el acompañamiento que necesitas
          en cada etapa de tu vida.
        </p>
        <a href="#contact" className="cta-button">
          Empecemos tu viaje
        </a>
      </div>
    </section>
  );
}

export default Hero;
