import React, { useEffect, useState } from "react";
import "../css/Testimonials.css";

function Testimonials() {
  const [activeIndex, setActiveIndex] = useState(0);
  const testimonials = [
    {
      text: "“Gracias a este acompañamiento, he descubierto una nueva forma de entender mis emociones.”",
      author: "– Cliente satisfecho",
    },
    {
      text: "“El espacio de confianza que se brindó me ayudó a encontrar claridad en momentos difíciles.”",
      author: "– Cliente satisfecho",
    },
    {
      text: "“La orientación recibida fue clave para mi crecimiento personal y emocional.”",
      author: "– Cliente satisfecho",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <section id="testimonials" className="testimonials">
      <h2>Testimonios</h2>
      <div className="container-testimonials">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`testimonial-item ${
              index === activeIndex ? "active" : ""
            }`}
          >
            <p>{testimonial.text}</p>
            <p>{testimonial.author}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
